import React from 'react'

import { functions, path, useApp } from '@wap-client/core'

import ArrowDown from '@/public/images/arrow-down.svg'

import { BannerProps } from './types'
import Image from 'next/image'

const Banner: React.FunctionComponent<BannerProps> = ({
  background,
  children,
  downArrow,
  textAlign = 'left',
  isDetayBanner = false,
}) => {
  const app = useApp()

  return (
    <div
      className={functions.classnames(
        'banner',
        isDetayBanner && 'detail-banner'
      )}
      style={{
        backgroundImage: `url('${path.asset(
          app.environment,
          background?.src
        )}')`,
        textAlign: textAlign,
      }}
    >
      {children}

      {downArrow && (
        <div className="banner__arrow-down">
          <Image src={ArrowDown} alt={app.settings.translations['downPage']} />
        </div>
      )}
    </div>
  )
}

export default Banner
